import React from 'react';
import { Link, graphql } from 'gatsby';
import { Container, Row, Col, Card } from 'react-bootstrap';

import Layout from '../components/layout/layout';
// import Seo from '../components/seo/seo';
import InsuranceProviders from '../components/insuranceProviders/insuranceProviders';
import FreedomBanner from '../components/freedomBanner/freedomBanner';
import SectionHero from '../components/wordPress/sections/sectionHero/sectionHero';
import Seo from '../components/seo/seo';

const IndexPage = ({ data }) => {
  // Map ACF content to variable
  const page = data.wpPage.pageHome;

  return (
    <Layout>
      <Seo
        canonical={`https://www.essentialbehavioral.com/`}
      />

      <>
        <SectionHero
          buttonDefaults={data.wp.globalContent.globalButtons.buttonSettings}
          sectionContent={page.homeHeader}
        />

        <section className={`py-5`}>
          <Container>
            <Row>
              <Col xs={12} lg={6} className={`mb-4`}>
                <Card className={`p-4`}>
                  <Card.Body>
                    <div className={`mb-3`}>
                      <h2>What We Treat</h2>
                      <p>We offer comprehensive treatment programs for substance use and co-occurring disorders.  Whether you struggle with alcohol overuse, prescription medication overuse, or use of illicit drugs with or without anxiety, depression and other mental health issues, our experienced medical doctors and wellness staff treat each client individually with a focus on your long term recovery.</p>
                    </div>

                    <Link
                      to={`/what-we-treat/`}
                      className={`btn btn-primary stretched-link`}
                    >
                      Learn About Our Expertise
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} lg={6} className={`mb-4`}>
                <Card className={`p-4`}>
                  <Card.Body>
                    <div className={`mb-3`}>
                      <h2>What To Expect</h2>
                      <p>At Clean &amp; Sober Detox, you can expect confidential, nurturing care from experienced professionals who prioritize your whole mental health.  We offer multiple levels of care including Detoxification, Residential, Day and Intensive Outpatient Programs to meet each client's unique therapeutic needs.</p>
                    </div>

                    <Link
                      to={`/how-we-treat/`}
                      className={`btn btn-primary stretched-link`}
                    >
                      Learn About The Experience
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        <hr/>

        <section className={`py-5`}>
          <InsuranceProviders />

          <Container>
            <Row className={`justify-content-center text-center mt-4`}>
              <Col xs={12} md={9} lg={7}>
                <p>We work with a number of insurance carriers who can help you cover the cost of treatment. Since insurance may not always offer full coverage, we also offer flexible payment options to make treatment affordable regardless of your carrier.</p>
                <Link to={`/insurance/`} className={`btn btn-primary mt-4`}>
                  Learn How Insurance Covers You
                </Link>
              </Col>
            </Row>
          </Container>
        </section>

        <FreedomBanner />
      </>
    </Layout>
  );
};

export const query = graphql`
  query {
    wpPage(databaseId: { eq: 181 }) {
      title
      seo {
        metaTitle
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      pageHome {
        homeHeader {
          fieldGroupName
          content
          htmlClass
          background {
            backgroundType
            backgroundImage {
              localFile {
                ...imageFluidLarge
              }
            }
            backgroundVideo {
              localFile {
                publicURL
              }
            }
            backgroundVideoCover {
              localFile {
                publicURL
              }
            }
          }
          buttonLayout {
            showButton
            overrideButtonSettings
            buttonSettings {
              buttonText
              linkType
              internalLink {
                ... on WpPage {
                  uri
                }
                ... on WpPost {
                  uri
                }
              }
              externalLink
              htmlClass
            }
          }
        }
        telehealth {
            telehealthHeadline
            telehealthContent
            telehealthButtonText
            telehealthImage {
                altText
                localFile {
                    ...imageFluidMedium
                }
            }
        }
        funnels {
          content
          buttonText
          buttonLink {
            ... on WpPage {
              slug
            }
          }
        }
        insurance {
          insuranceContent
          insuranceButtonText
        }
      }
    }
    wp {
      globalContent {
        globalButtons {
          buttonSettings {
            buttonText
            linkType
            internalLink {
              ... on WpPost {
                id
                uri
              }
              ... on WpPage {
                id
                uri
              }
            }
            externalLink
            htmlClass
          }
        }
      }
    }
  }
`;

export default IndexPage;
